
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Add these styles to your global CSS or import them into your component */

.deactivate-account-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.deactivate-account-heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.deactivate-account-label {
  display: block;
  margin-bottom: 10px;
}

.deactivate-account-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.deactivate-account-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.deactivate-account-message {
  margin-top: 15px;
  font-size: 14px;
  color: red;
}

/* .quill > .ql-toolbar:first-child {
  display: none !important;
} */


.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -4rem;
  margin-bottom: -5rem;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-transform: none;
  font-family: cursive;
  font-weight: 700;
  font-size: 30px;
  color: rgb(68, 67, 67);
}
.App {
  /* padding: 0.5rem 3.5rem; */
  color: var(--black);
  background: rgb(249, 250, 251);
  height: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
  padding: 1rem;
}

.AppGlass {
  display: grid;
  width: 97%;
  height: 97%;
  background: rgb(249, 250, 251);
  border-radius: 2rem;
  overflow: hidden;
}
.AppGlass::-webkit-scrollbar{
  display: none;
}
@media screen and (max-width: 1200px) {
  .AppGlass{
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  } 
}

@media screen and (max-width: 768px) {
  .AppGlass{
    grid-template-columns: 1fr;
  } 
}
