.navbar{

    background: linear-gradient(to bottom, rgb(22, 56, 94), rgb(22, 56, 94) 75%, #666565);
}
.navbrand{
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 700;
    color: aliceblue;
    text-decoration: none;
    margin-right: 6rem;
}
.navbrand:hover{
    color: rgb(235, 235, 181);
    text-decoration: none;
}
.navlink{
    font-size: 14px;
    font-family: inherit;
    padding-left: 2rem;
    font-weight: 600;
    color: aliceblue;
    text-decoration: none;
    border: none;
    background-color: rgb(22, 56, 94);
}
.navlink:hover{
    color: rgb(235, 235, 181);
    text-decoration: none;
}

.appdetails{
    align-items: center;
    margin-bottom: 11rem;
}
.appimage{
    padding-top: -12rem;
    width: 200px; /* Adjust this value as needed */
    height: 200px; /* Adjust this value as needed */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.appcard{margin-bottom: 7rem;}
.applink{text-decoration: none;font-size: 15px;font-weight: 600;}
.appimaged{
    width: 150px;
    height: 150px;
}