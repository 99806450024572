.mathquill4quill-operator-button ,.ql-formula{
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30.2%;
    height: 50px;
    background-color: #fff;
    border-color: #000;
    border-radius: 7px;
    border-width: 2px;
  }
  .mathquill4quill-operator-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  