body::-webkit-scrollbar{
  display: none;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  background: rgb(249, 250, 251);
}

p, a, div, h1, h2, h3, h4, h5, h6, span {
  font-family: "Inter", sans-serif !important;
}

a {
  text-decoration: none !important;
}

